<template>
  <div class="inner-layout-800">

    <div class="page-title">회원탈퇴</div>

    <div class="pageAgreementFullText bgcolor-white3" v-html="policy"></div>

    <div class="mb-50">
      <label>
        <input type="checkbox" v-model="isCheck">
        <span class="input-icon"></span>
        <span class="color-1">(필수)&nbsp;</span>
        위 내용을 모두 확인하였습니다.
      </label>
      <div v-show="isShowCheckMsg" class="error-message">{{$msg('required.agree')}}</div>
    </div>

    <validateForm ref="form">
      <div class="subtitle-1">탈퇴사유<span class="color-1">*</span></div>
      <div>
        <textarea rows="10" v-model="reason" maxlength="1000"></textarea>
        <div v-show="isShowReasonMsg" class="error-message">{{$msg('required.agree')}}</div>
      </div>
    </validateForm>

    <div class="mt-20 ta-center">
      <div class="btn w-120 color-1" @click="confirmWithdrawalPopup">회원탈퇴</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "mypageWithdrawal",
  data() {
    return {
      policy: null,
      isCheck: false,
      reason: null,
      isShowCheckMsg: false,
      isShowReasonMsg: false,
    }
  },
  created() {
    this.setPolicy()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
      clearSession: 'session/clearSession',
    }),
    async setPolicy() {
      const { result, data } = await this.$api.getPolicy({ target: 5 })
      if (result === 'success') this.policy = data
    },
    async withdrawal() {
      if (this.$refs.form.validate()) {
        const { result } = await this.$api.withdrawal({ reason: this.reason })
        if (result === 'success') this.alertPopup()
      }
    },
    validate() {
      this.isShowCheckMsg = false
      this.isShowReasonMsg = false
      if (!this.isCheck) {
        this.isShowCheckMsg = true
        return false
      }
      if (!this.reason) {
        this.isShowReasonMsg = true
        return false
      }
      return true
    },
    alertPopup() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('ok.withdrawal'),
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          this.clearSession()
          localStorage.removeItem('auth')
          localStorage.removeItem('id')
          localStorage.removeItem('email')
          localStorage.removeItem('name')
          localStorage.removeItem('url')
          localStorage.removeItem('target')
          localStorage.removeItem('phone')
          location.href = "/"
        },
      })
    },
    confirmWithdrawalPopup() {
      if (this.validate()) {
        this.setMsgPopup({
          type: 'confirm',
          message: this.$msg('confirm.withdrawal'),
          okay: 'REMOVE',
          okayCallback: () => {
            this.setMsgPopup()
            this.withdrawal()
          },
          cancel: 'CLOSE',
          cancelCallback: () => this.setMsgPopup()
        })
      }
    },
  },
}
</script>
